import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import reportsService from "./reports-service";
import Utility from "../../../shared/utility";
import BackToTop from "../../common/back-to-top.vue";
export default {
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    backSlash: true,
    backSlashParent: true,
    showEdit: true,
    isFormValid: false,
    apiData: [],
    switchData: [],
    totalRecords: 0,
    outputBusiness: false,
    businessDisabled: false,
    outputClass: false,
    classDisabled: false,
    outputStandardCost: false,
    standardCostDisabled: false,
    outputOnPO: false,
    onPODisabled: false,
    outputSubBusiness: false,
    outputModel: false,
    modelDisabled: false,
    outputPurchasePrice: false,
    purchasePriceDisabled: false,
    outputFGI: false,
    FGIDisabled: false,
    outputPartNumber: false,
    partNumberDisabled: false,
    outputOEM: false,
    OEMDisabled: false,
    outputAverageCost: false,
    averageCostDisabled: false,
    outputMRB: false,
    MRBDisabled: false,
    outputNoStock: false,
    noStockDisabled: false,
    outputPartDescription: false,
    partDescriptionDisabled: false,
    outputBarcoded: false,
    barcodedDisabled: false,
    outputCostLatestPO: false,
    costLatestPODisabled: false,
    outputTBE: false,
    TBEDisabled: false,
    outputPartType: false,
    partTypeDisabled: false,
    outputBinType: false,
    binTypeDisabled: false,
    outputRepairPrice: false,
    repairPriceDisabled: false,
    outputWIP: false,
    WIPDisabled: false,
    outputWarehouse: false,
    warehouseDisabled: false,
    outputBinLimit: false,
    binLimitDisabled: false,
    outputSalesPrice: false,
    salesPriceDisabled: false,
    outputScrap: false,
    scrapDisabled: false,
    outputAvgSalesPrice: false,
    outputAllocated: false,
    allocatedDisabled: false,
    outputExchangePrice: false,
    exchangePriceDisabled: false,
    outputBacklogged: false,
    backloggedDisabled: false,
    disableReset: true,
    disableApply: true,
    tableData: [],
    exportDisable: true,
    excelName: "",
    WIPItems: [],
    WIPDialog: false,
    searchReports: "",
    panel: 1,
    disableCostFields: false,
    disablePriceFields: false,
    showTableData: true,
    reportHeaders: [
      { text: "Business", align: "start", value: "business", class: "primary customwhite--text" },
      { text: "Part Number", value: "part_num", class: "primary customwhite--text" },
      { text: "Part Description", value: "part_desc", class: "primary customwhite--text" },
      { text: "Part Type", value: "part_type", class: "primary customwhite--text" },
      { text: "Warehouse", value: "warehouse", class: "primary customwhite--text" },
      { text: "Class", value: "printer_class", class: "primary customwhite--text" },
      { text: "Model", value: "model", class: "primary customwhite--text" },
      { text: "OEM", value: "oem", class: "primary customwhite--text" },
      { text: "Barcoded (Y/N)", value: "barcoded", class: "primary customwhite--text" },
      { text: "Bin Type", value: "bin_type", class: "primary customwhite--text" },
      { text: "Bin Limit", value: "bin_limit", class: "primary customwhite--text" },
      { text: "Standard Cost", value: "costfixed", class: "primary customwhite--text" },
      { text: "Purchase Price", value: "price", class: "primary customwhite--text" },
      { text: "Average Cost", value: "average_cost", class: "primary customwhite--text" },
      { text: "Cost Latest PO", value: "cost_latest_po", class: "primary customwhite--text" },
      { text: "Sales Price", value: "avg_sales_price", class: "primary customwhite--text" },
      { text: "Repair Price", value: "rep_price", class: "primary customwhite--text" },
      { text: "Exchange Price", value: "ex_price", class: "primary customwhite--text" },
      { text: "FGI", value: "fgi", class: "primary customwhite--text" },
      { text: "Allocated", value: "allocated", class: "primary customwhite--text" },
      { text: "Backlogged", value: "backlog", class: "primary customwhite--text" },
      { text: "On PO", value: "on_po", class: "primary customwhite--text" },
      { text: "MRB", value: "mrb", class: "primary customwhite--text" },
      { text: "TBE", value: "tbe", class: "primary customwhite--text" },
      { text: "WIP", value: "wip", class: "primary customwhite--text" },
      { text: "Scrap", value: "scp", class: "primary customwhite--text" },
    ],
    WIPHeaders: [
      { text: "Location", align: "start", value: "Location" },
      { text: "Quantity", value: "Qty" },
    ],
    finalHeaders: [],
    json_fields: {
      Business: "business",
      "Part Number": "part_num",
      "Part Description": "part_desc",
      "Part Type": "part_type",
      Warehouse: "warehouse",
      Class: "printer_class",
      Model: "model",
      OEM: "oem",
      "Barcoded (Y/N)": "barcoded",
      "Bin Type": "bin_type",
      "Bin Limit": "bin_limit",
      "Standard Cost": "costfixed",
      "Purchase Price": "price",
      "Average Cost": "average_cost",
      "Cost Latest PO": "cost_latest_po",
      "Sales Price": "avg_sales_price",
      "Repair Price": "rep_price",
      "Exchange Price": "ex_price",
      FGI: "fgi",
      Allocated: "allocated",
      Backlogged: "backlog",
      "On PO": "on_po",
      MRB: "mrb",
      TBE: "tbe",
      WIP: "wip",
      Scrap: "scp",
    },
  }),
  async created() {
    this.primaryName = this.$cookies.get("primaryName");
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    this.actionList = data?.actionList;
    this.disableCostFields = this.actionSetter("CostFields");
    this.disablePriceFields = this.actionSetter("PriceFields");
    if (this.$route.params.apiData !== null && this.$route.params.apiData !== undefined) {
      let routeData = atob(this.$route.params.apiData);
      this.apiData = JSON.parse(routeData);
    } else {
      this.$router.go(-1);
    }
    if (this.apiData !== undefined) {
      this.outputColumns();
    }
  },
  mounted() {},
  methods: {
    //API call to get switch data
    outputColumns() {
      if (this.apiData.length !== 0) {
        let obj = {
          user_id: parseInt(this.userId),
          proj_id: parseInt(this.apiData.proj_id),
          bu_id: parseInt(this.apiData.bu_id),
          sbu_id: 0,
          ptype_id: parseInt(this.apiData.ptype_id),
          parent_class_id: parseInt(this.apiData.parent_class_id),
          class_id: parseInt(this.apiData.class_id),
          ware_id: parseInt(this.apiData.ware_id),
          loc: this.apiData.loc,
          part_num: this.apiData.part_num,
        };
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios.get("/rt/stock_status_columns?user_id=" + this.userId).then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = response.data.body;
            this.switchData = responseData[0];
            this.outputBusiness = this.switchData?.business;
            this.outputClass = this.switchData?.printer_class;
            this.outputStandardCost = this.switchData?.costfixed;
            this.outputOnPO = this.switchData?.on_po;
            this.outputSubBusiness = this.switchData?.sbu_code;
            this.outputModel = this.switchData?.model;
            this.outputPurchasePrice = this.switchData?.cost;
            this.outputFGI = this.switchData?.fgi;
            this.outputPartNumber = this.switchData?.part_num;
            this.outputOEM = this.switchData?.oem;
            this.outputAverageCost = this.switchData?.average_cost;
            this.outputMRB = this.switchData?.mrb;
            this.outputNoStock = this.switchData?.no_stock;
            this.outputPartDescription = this.switchData?.part_desc;
            this.outputBarcoded = this.switchData?.barcoded;
            this.outputCostLatestPO = this.switchData?.cost_latest_po;
            this.outputTBE = this.switchData?.tbe;
            this.outputPartType = this.switchData?.part_type;
            this.outputBinType = this.switchData?.bin_type;
            this.outputRepairPrice = this.switchData?.rep_price;
            this.outputWIP = this.switchData?.wip;
            this.outputWarehouse = this.switchData?.warehouse;
            this.outputBinLimit = this.switchData?.bin_limit;
            this.outputSalesPrice = this.switchData?.price;
            this.outputScrap = this.switchData?.scp;
            this.outputAvgSalesPrice = this.switchData?.avg_sales_price;
            this.outputAllocated = this.switchData?.allocated;
            this.outputExchangePrice = this.switchData?.ex_price;
            this.outputBacklogged = this.switchData?.backlog;
          }
        });
        this.axios
          .post("/rt/stock_status_report_new", obj)
          .then((response) => {
            if (response.status == 200) {
              let responseData = response.data.body;
              this.tableData = responseData;
              this.reportData = this.tableData;
              if (this.tableData.length > 0) {
                this.showTableData = true;
                this.exportDisable = false;
                this.totalRecords = this.tableData.length;
              } else {
                this.showTableData = false;
                this.exportDisable = true;
                this.totalRecords = 0;
              }
              this.finalHeaders = this.reportHeaders.filter((obj) => {
                for (let key in this.switchData) {
                  if (obj.value == key && this.switchData[key]) {
                    return obj.value;
                  }
                }
              });
              this.disableApply = true;
              this.disableReset = true;
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        this.$router.go(-1);
      }
    },
    //Query WIP button click event
    async onQueryWIP(item) {
      let obj = {
        user_id: parseInt(this.userId),
        bu_id: parseInt(item.bu_key),
        part_id: parseInt(item.part_key),
        ware_id: parseInt(item.ware_key),
      };
      let showMessage = false;
      let wipData = await reportsService.stockWIPDrillData("post", obj, showMessage);
      if (wipData.message !== "NA") {
        this.WIPItems = wipData;
        this.WIPDialog = true;
      }
    },
    //Apply button click event
    applyChecks() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let jsonColUpdate = {
        json: JSON.stringify({
          user_id: parseInt(this.userId),
          business: this.outputBusiness ? 1 : 0,
          sbu_code: 0,
          class: this.outputClass ? 1 : 0,
          costfixed: this.outputStandardCost ? 1 : 0,
          on_po: this.outputOnPO ? 1 : 0,
          model: this.outputModel ? 1 : 0,
          cost: this.outputPurchasePrice ? 1 : 0,
          fgi: this.outputFGI ? 1 : 0,
          part_num: this.outputPartNumber ? 1 : 0,
          oem: this.outputOEM ? 1 : 0,
          average_cost: this.outputAverageCost ? 1 : 0,
          mrb: this.outputMRB ? 1 : 0,
          no_stock: this.outputNoStock ? 1 : 0,
          part_desc: this.outputPartDescription ? 1 : 0,
          Barcoded: this.outputBarcoded ? 1 : 0,
          cost_latest_po: this.outputCostLatestPO ? 1 : 0,
          tbe: this.outputTBE ? 1 : 0,
          part_type: this.outputPartType ? 1 : 0,
          bin_type: this.outputBinType ? 1 : 0,
          rep_price: this.outputRepairPrice ? 1 : 0,
          wip: this.outputWIP ? 1 : 0,
          warehouse: this.outputWarehouse ? 1 : 0,
          bin_limit: this.outputBinLimit ? 1 : 0,
          price: this.outputSalesPrice ? 1 : 0,
          scp: this.outputScrap ? 1 : 0,
          avg_sales_price: this.outputAvgSalesPrice ? 1 : 0,
          allocated: this.outputAllocated ? 1 : 0,
          ex_price: this.outputExchangePrice ? 1 : 0,
          backlog: this.outputBacklogged ? 1 : 0,
        }),
      };
      this.axios
        .post("/rt/stock_status_column_save", jsonColUpdate)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.switchData = responseData[0];
            this.finalHeaders = this.reportHeaders.filter((obj) => {
              for (let key in this.switchData) {
                if (obj.value == key && this.switchData[key]) {
                  return obj.value;
                }
              }
            });
            this.reportData = this.tableData;
            this.disableApply = true;
            this.disableReset = true;
            this.outputBusiness = this.switchData?.business;
            this.outputClass = this.switchData?.class;
            this.outputStandardCost = this.switchData?.costfixed;
            this.outputOnPO = this.switchData?.on_po;
            this.outputSubBusiness = this.switchData?.sbu_code;
            this.outputModel = this.switchData?.model;
            this.outputPurchasePrice = this.switchData?.cost;
            this.outputFGI = this.switchData?.fgi;
            this.outputPartNumber = this.switchData?.part_num;
            this.outputOEM = this.switchData?.oem;
            this.outputAverageCost = this.switchData?.average_cost;
            this.outputMRB = this.switchData?.mrb;
            this.outputNoStock = this.switchData?.no_stock;
            this.outputPartDescription = this.switchData?.part_desc;
            this.outputBarcoded = this.switchData?.barcoded;
            this.outputCostLatestPO = this.switchData?.cost_latest_po;
            this.outputTBE = this.switchData?.tbe;
            this.outputPartType = this.switchData?.part_type;
            this.outputBinType = this.switchData?.bin_type;
            this.outputRepairPrice = this.switchData?.rep_price;
            this.outputWIP = this.switchData?.wip;
            this.outputWarehouse = this.switchData?.warehouse;
            this.outputBinLimit = this.switchData?.bin_limit;
            this.outputSalesPrice = this.switchData?.price;
            this.outputScrap = this.switchData?.scp;
            this.outputAvgSalesPrice = this.switchData?.avg_sales_price;
            this.outputAllocated = this.switchData?.allocated;
            this.outputExchangePrice = this.switchData?.ex_price;
            this.outputBacklogged = this.switchData?.backlog;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Export button click event
    fileName() {
      this.excelName =
        "Stock_Status_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //Action Setter
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
  },
};
